/**
* @flow
*
* translations for pt make sure to keep all the keys
* sorted alphabetically in order to easily search for words
*
*/
const pt = {

  	/*
   	* Urls that change depending on language
	*/
  buy_url: process.env.REACT_APP_BUY_URL_PT,
  activities_url: process.env.REACT_APP_ACTIVITIES_URL,

  

	GENERAL:	"Geral",
	INTER:	"Interpessoal",
	INTRA:	"Intrapessoal",
	KIN:	"Cinestésico",
	LIN:	"Linguística",
	MAT:	"Lógica",
	MUS:	"Musical",
	NAT:	"Naturalista",
	VIS:	"Visual",
	about:	"Inicial",
	account_confirmed_form_subtitle:	"Sua conta foi confirmada com sucesso.",
	account_confirmed_form_title:	"Conta confirmada",
	account_settings:	"Configuração da conta",
	account_settings_profiles:	"Perfis",
	activities:	"Actividades",
	add_existing_course:	"Adicionar curso existente",
	add_existing_student:	"Adicionar aluno existente",
	add_new_course:	"Adicionar novo curso",
	add_new_profile:	"Adicionar novo perfil",
	add_new_student:	"Adicionar novo aluno",
	age:	"Idade",
	all:	"Todos",
	all_fields_are_required:	"Por favor preencha todos os campos",
	against_course:	"Aluno em relação ao curso",
	back_to_courses:	"Voltar aos cursos",
	best_progress:	"Maior progresso",
	best_worked:	"Mais exercitado",
	blog:	"Blog",
	buy:	"Loja",
	buy_now:	"Comprar agora",
	cancel:	"Cancelar",
	cant_create_session:	"Você já tem uma sessão ativa, efetue logout e tente novamente.",
	challenge:	"Desafios",
	challenges:	"Competências",
	children_addition_form_subtitle:	"Definir o perfil da criança",
	children_addition_form_title:	"Crie uma nova conta PleIQ",
	configuration:	"Configuração",
	confirm:	"Confirme",
	congratulations_form_subtitle:	"Sua conta foi criada com sucesso.",
	congratulations_form_title:	"Parabéns!",
	continue:	"Continuar",
	course:	"Curso",
	course_data:	"Dados do curso",
	course_name:	"Nome do curso",
	course_students:	"Alunos do curso",
	courses:	"Cursos",
	courses_progress_in_time:	"Progresso do curso ao longo do tempo",
	courses_section_placeholder:	"Você pode organizar seus alunos criando níveis e cursos.",
	create_child_profile:	"Criar perfil filho",
	create_course:	"Criar curso",
	create_level:	"Criar nível",
	create_new_level:	"Criar novo nível",
	create_student:	"Criar aluno",
	current_profile:	"Perfil atual",
	current_progress:	"Progresso atual",
	delete:	"Excluir",
	delete_child_confirmation_message:	"Se você confirmar esta ação, as informações da criança serão excluídas permanentemente.",
	delete_child_confirmation_title:	"Tem certeza?",
	delete_course_confirmation_message:	"Se você confirmar esta ação, as informações deste curso serão excluídas permanentemente.",
	delete_course_confirmation_title:	"Tem certeza?",
	delete_level_confirmation_message:	"Se você confirmar esta ação, as informações nesse nível serão excluídas permanentemente.",
	delete_level_confirmation_title:	"Tem certeza?",
	delete_student_confirmation_message:	"Se você confirmar esta ação, as informações deste aluno serão excluídas permanentemente.",
	delete_student_confirmation_title:	"Tem certeza?",
	edit:	"Editar",
	edit_course:	"Editar curso",
	edit_course_students:	"Editar alunos do curso",
	edit_level:	"Editar nível",
	edit_level_courses:	"Editar cursos de nível",
	edit_student:	"Editar aluno",
	effort:	"Esforço",
	effort_vs_performance:	"Esforço versus desempenho",
	email:	"E-mail",
	email_confirmation_form_resend_email_button:	"Reenviar email de confirmação",
	email_confirmation_form_subtitle:	"Confirme sua conta a partir do seu email",
	email_confirmation_form_title:	"Crie uma nova conta PleIQ",
	email_placeholder:	"E-mail",
	email_placeholder_retype:	"Confirme seu email",
	email_retype_alert:	"Ambos os e-mails devem ser os mesmos",
	english:	"Inglês",
	expired:	"O token expirou, solicite um novo",
	explore:	"Explorar",
	faq:	"Perguntas",
	first_name:	"Nome",
	forgot_password:	"Esqueceu sua senha?",
	forum:	"Fórum",
	how_find_my_code:	"Onde posso encontrar meu código?",
	how_find_my_code_instructions:	"Encontre seu código de 9 caracteres no produto PleIQ/Caligrafix que você possui.",
	how_to_improve:	"Como melhorar?",
	intelligence_screen_achievements_label:	"Progresso por concorrência",
	intelligence_screen_current_progress_label:	"Progresso atual",
	intelligence_screen_timeline_label:	"Progresso no tempo",
	intelligences_progress_analysis:	"Análise do progresso nas inteligências",
	interpersonal:	"Interpessoal",
	intrapersonal:	"Intrapessoal",
	kinesthetic:	"Cinestésico",
	language:	"Idioma primeiro",
	language2:	"Idioma secundário",
	last_name:	"Sobrenome",
	less_progress:	"Menos progresso",
	less_worked:	"Menos exercitado",
	level:	"Nível",
	level_courses:	"Cursos de nível",
	level_data:	"Dados de nível",
	level_name:	"Nome do nível",
	levels_courses_and_students:	"Níveis, cursos e estudantes",
	linguistics:	"Linguística",
	load_more:	"Mais resultados",
	logic:	"Lógica",
	login:	"Iniciar sessão",
	logout:	"Fechar Sessão",
	music:	"Músico",
	name:	"Nome",
	nature:	"Naturalista",
	new:	"Novo",
	new_record:	"Novo recorde",
	no_account_yet:	"Ainda não tem uma conta? Inscreva-se agora",
	no_activity_yet:	"Nenhuma atividade ainda",
	no_children_yet:	"Sem filhos associados",
	not_pleiq_yet:	"Ainda não tem um produto PleIQ/Caligrafix?",
	oauth_form_subtitle:	"Registre-se com:",
	oauth_form_title:	"Crie uma nova conta PleIQ",
	oauth_form_with_email:	"Registar com o meu email",
	parent_settings_details:	"Dados pessoais",
	parent_sign_up_form_subtitle:	"Digite seus dados",
	parent_sign_up_form_title:	"Crie uma nova conta PleIQ",
	password:	"Senha",
	password_confirmation:	"Confirmação de senha",
	password_reset_successfully:	"Sua senha foi alterada corretamente",
	password_reset_token:	"Token",
	password_succesfully_updated:	"Sua senha foi atualizada com sucesso",
	passwords_should_match:	"As senhas devem ser as mesmas",
	performance:	"Desempenho",
	pick_a_course:	"Pesquisar curso por nome",
	pick_a_student:	"Procurar aluno por nome",
	play_and_comeback:	"Vá jogar e volte mais tarde",
	pleiq_code:	"Código PleIQ",
	pleiq_code_form_subtitle:	"Adicione o código PleIQ",
	pleiq_code_form_title:	"Crie uma nova conta PleIQ",
	popular:	"Populares",
	profiles:	"Perfis",
	progress:	"Progresso",
	recent_activity:	"Atividade recente",
	reports:	"Relatórios",
	reports_preferences_weekly:	"Relatórios semanais de progresso da criança (s)",
	receive_marketing_emails:	"Receba notícias sobre PleIQ",
	terms_and_conditions:	"Ao pressionar o botão Continuar, você concorda com nossos <a href=\"//pleiq.com/pt/terms\"> termos e condições </a>.",
	required:	"Esta informação é necessária",
	reset_password_by_email:	"Redefinir senha por e-mail",
	reset_password_email_sent:	"Você receberá um email com instruções sobre como redefinir sua senha em alguns minutos.",
	save:	"Salvar",
	school_current_general_progress:	"Progresso atual da escola",
	school_current_progress_by_course:	"Progresso atual por curso",
	school_progress_by_intelligence_title:	"Competências trabalhadas pela inteligência",
	school_settings_details:	"Dados da escola",
	schools:	"Escolas",
	search:	"Pesquisar",
	search_student:	"Procurar aluno",
	settings:	"Configuração",
	shop:	"Loja",
	signin_form_subtitle:	"Digite seus dados",
	signin_form_title:	"Entrar no PleIQ",
	director_form_tab1_title: 'Data First Administrator Instituição educacional',
	director_form_name: 'Nome',
	director_form_email: 'Email',
	director_form_confirm: 'Confirma o email',
	director_form_served: 'Servido por',
	spanish:	"Espanhol",
	store:	"Loja",
	student:	"Estudante",
	student_data:	"Dados do aluno",
	student_name:	"Nome do aluno",
	student_not_found_message:	"O aluno que você solicitou não foi encontrado",
	student_progress_name:	"%{Name} progresso",
	students:	"Alunos",
	students_search_not_found:	"Nenhum aluno foi encontrado.",
	students_search_title:	"Procurar alunos",
	tooltips_current_school_courses_average_progress:	"Comparação do progresso geral dos cursos para cada nível.",
	tooltips_current_school_courses_progress:	"Comparação do andamento dos cursos para cada inteligência.",
	tooltips_current_school_progress:	"Progresso global atual acumulado por cada inteligência.",
	tooltips_intelligence_screen_achievements_label:	"Progresso de acordo com competências específicas.",
	tooltips_intelligences_progress_analysis:	"Principais indicadores de progresso de acordo com tempo e esforço.",
	tooltips_student_vs_course_progress:	"Aluno x Curso",
	treasures:	"Tesouros",
	unassigned:	"Não atribuído",
	unique_account:	"Conta única",
	unique_account_config_step_1:	"Defina os detalhes da conta única",
	unique_account_config_step_2:	"Compartilhe dados com professores parceiros.",
	unique_account_config_step_3:	"Entre nos dispositivos com o PleIQ usando os dados da Conta Única.",
	unique_account_form_title:	"Dados da conta única",
	unique_account_info_paragraph_1:	"Com a conta única, você pode configurar todos os dispositivos que usam o PleIQ em sua escola sem ter que se preocupar em criar contas para cada um.",
	unique_account_info_paragraph_2:	"Configure o PleIQ na sua escola facilmente, seguindo estas 3 etapas:",
	unique_account_info_title:	"O que é a conta única?",
	unique_account_update_warning_paragraph:	"Se você modificar os dados, todos os dispositivos que atualmente usam o PleIQ com esta conta serão dessincronizados e precisarão de login manual.",
	unique_account_update_warning_title:	"Atenção!",
	update_password:	"Alterar senha",
	user_password_new_form_subtitle:	"Você receberá um email com instruções para alterar sua senha",
	user_password_new_form_title:	"Instruções para alterar sua senha",
	user_password_reset_form_title:	"Digite sua nova senha",
	visual:	"Visual",
	where_my_code:	"Onde posso encontrar meu código?",
	years_old:	"%{smart_count} ano |||| %{smart_count} anos",
	your_email:	"E-mail",
	your_last_name:	"Sobrenome",
	your_name:	"Nome",
	auth_required_message:	"Você deve estar logado para executar essa ação.",
	connection_trouble_message:	"Há um problema na conexão com o servidor. <a href=\".\"> Por favor, tente novamente. </a>",
	server_unavailable_message: 'Olá! No momento, estamos fazendo melhorias para oferecer um serviço melhor. Volte em alguns instantes!',
	invalid_credentials:	"E-mail ou senha inválidos",
	invalid:	"As informações fornecidas não são válidas",

	invalid_code: 'O código inserido não existe, verifique se está bem escrito',
	in_use: 'O código inserido já foi usado em uma conta existente',
	not_expired: 'Você já tem uma licença ativa correspondente a este código',
	no_internet_connection_message:	"Você não tem uma conexão com a Internet, verifique sua conexão e <a href=\".\"> tente novamente. </a>",
	pleiq_code_format:	"O formato do código deve ser: XXX-XXX-XXX",
	should_be_at_least_2:	"Você deve usar pelo menos 2 caracteres",
	should_be_at_least_8:	"Você deve usar pelo menos 8 caracteres",
	taken:	"O valor fornecido já foi utilizado por outro usuário",
	unauthorized_account_role:	"A conta fornecida não foi autorizada para o uso deste aplicativo",
	valid_email:	"E-mail inválido",
	reset_password_token_invalid:	'<p>O link usado expirou por motivos de segurança após o uso anterior ou por 24 horas sem usá-lo desde que foi enviado.</p>Defina a senha da sua conta solicitando um novo link <p><a href="/users/password/new">clique aqui.</a></p>',
	reset_password_param_missing:	"Parâmetros inválidos, entre em contato com support@pleiq.com se você acredita que isso é um erro.",
	session_expired:	"Sua sessão expirou, faça login novamente.",
	license_expired:	"A licença desta conta expirou, entre em contato com seu provedor.",
	email_doesnt_match:	"Ambos os e-mails devem ser os mesmos",
	day:	"Diariamente",
	month:	"Mensal",
	no_data:	"Não há dados",
	week:	"Semanal",
	week_date:	"Semana",
	title_parent_dashboard:	"Progresso",
	title_parent_intelligence:	"Progresso em inteligências",
	title_registration_account_confirmed:	"Conta confirmada",
	title_registration_activation:	"Ativar conta",
	title_registration_children:	"Adicionar filhos",
	title_registration_confirm_email:	"Confirmar email",
	title_registration_congratulations:	"Parabéns!",
	title_registration_reset_password:	"Redefinir senha",
	title_registration_signin:	"Iniciar sessão",
	title_registration_signup:	"Inscreva-se",
	title_school_challenges:	"Progresso geral em inteligências",
	title_school_courses:	"Cursos",
	title_school_progress:	"Progresso geral",
	title_school_search_students:	"Procurar alunos",
	title_school_unique_account:	"Conta única",
	title_student_intelligence:	"Progresso do aluno em inteligência",
	title_student_progress:	"Progresso do aluno",
	title_user_settings:	"Preferências da conta",
	best_time:	"Novo recorde",
	challenge_completed:	"Desafio concluído",
	LIN_VIS_1_Cards:	"Associando letras a imagens",
	LIN_VIS_2_Space:	"Reconhecer letras no espaço",
	MAT_NAT_1_Animals:	"Números e contagem",
	MAT_VIS_1_Balloons:	"Identifique cores e formas",
	NAT_KIN_1_Recycle:	"Reciclar resíduos",
	MUS_LIN_1_Xylophone:	"Reconhecer notas musicais",
	KIN_LIN_1_Uppercase:	"Rastreando letras maiúsculas",
	KIN_LIN_2_Lowercase:	"Rastreando letras minúsculas",
	KIN_MAT_1_Numbers:	"Números de plotagem",
	KIN_MAT_2_Shapes:	"Rastrear formas geométricas",
	VIS_NAT_1_Draw:	"Formas para colorir",
	INTRA_LIN_1_Emotions:	"Identifique emoções próprias",
	INTER_LIN_Magic_Words:	"Reconhecer palavras mágicas",
	KIN_LIN_Cursive_Letters_Lowercase:	"Letras cursivas em minúsculas",
	KIN_LIN_Cursive_Letters_Uppercase:	"Letras cursivas em maiúsculas", 
	MAT_VIS_1_Sequence: 'Sequência de números', 
	MAT_VIS_1_Rings: 'Conclusão da quantidade',	 	
	LIN_VIS_1_Monster_Letters:'Fonética das letras',	 
	MAT_NAT_1_Cells: 'Soma das Celulas',
	LIN_VIS_1_Alphabet_Spiral: 'Espiral de letras',
	MUS_VIS_1_Notes_Runner:'Runner notas musicais',
  
	LIN_MAT_Count_Syllables_Filling_1: "Identificando o número de sílabas",
	LIN_MAT_Count_Syllables_Filling_2: "Identificando o número de sílabas 2",
	LIN_VIS_Coloring_Syllables_1: "Colorir por número de sílabas",
	LIN_VIS_Coloring_Syllables_2: "Colorir por número de sílabas 2",
	LIN_VIS_Coloring_Syllables_3: "Colorir por número de sílabas 3",
	LIN_MAT_Count_Syllables_1: "Contando as sílabas 1",
	LIN_MAT_Count_Syllables_2: "Contando as sílabas 2",
	LIN_MAT_Count_Syllables_3: "Contando as sílabas 3",
	LIN_VIS_Initial_Syllable_1: "Sílaba inicial",
	LIN_VIS_Initial_Syllable_2: "Sílaba inicial 2",
	LIN_VIS_Medial_Syllable_1: "Sílaba medial",
	LIN_VIS_Medial_Syllable_2: "Sílaba medial 2",
	LIN_VIS_Final_Syllable_1: "Sílaba final",
	LIN_VIS_Manipulation_Sylabic_1: "Manipulação de sílaba",
	LIN_VIS_Manipulation_Sylabic_2: "Manipulação silábica 2",
	LIN_KIN_Vocal_Initial_1: "Vogal inicial",
	LIN_KIN_Vocal_Initial_2: "Vocal Inicial 2",
	LIN_VIS_Vowel_Initial_Relation_1: "Pares de vogais iniciais",
	LIN_VIS_Vowel_Initial_Relation_2: "Pares de vogais iniciais 2",
	LIN_VIS_Vowel_Final_Relation_1: "Reconhecer a vogal final",
	LIN_VIS_Vowel_Sequence_1: "Sequência de vogais",
	LIN_VIS_Vowel_Sequence_Cards_1: "Som de vogal final",
	LIN_VIS_Vowel_Sequence_Cards_2: "Sequência vocálica",
	LIN_VIS_Coloring_Consonantal_1: "Coloração por som inicial consoante",
	LIN_MAT_Syllable_Kitchen_1: "Cozinha de sílabas 1",
	LIN_VIS_Capture_Final_Syllable_1: "Sílaba final no espaço",
	LIN_VIS_Objects_Syllables_1: "Soma de sílabas e objetos 1",
	LIN_VIS_Objects_Syllables_2: "Soma de sílabas e objetos 2",
	LIN_VIS_Objects_Syllables_3: "Soma de sílabas e objetos 3",
	LIN_VIS_Coloring_Final_Syllable_1: "Coloração por som de terminação de vogal",
	LIN_VIS_Form_New_Words_1: "Forme novas palavras 1",
	LIN_VIS_Memorice_Initial_Syllable_1: "Memorize a sílaba inicial",
	LIN_VIS_Vocal_Medial_Final_Filling_1: "Som medial e final de vogal",
	LIN_VIS_Sound_Consonantal_Cards_1: "Som consonantal inicial",
	LIN_VIS_Initial_Consonant_Timer_1: "Som consonantal inicial no espaço",
	LIN_VIS_Information_Integration_Cards_1: "Integração de informação",
	LIN_VIS_Syllable_Medial_Relation_1: "Pares de sílabas mediais",
	LIN_VIS_Grid_Medial_Consonantal_1: "Som consonantal medial P e M",
	LIN_VIS_Grid_Final_Consonantal_1: "O som da consoante final N, L e S",
	LIN_VIS_Grid_Initial_Consonantal_1: "Som consonantal inicial F, G, B, R e V",
	LIN_VIS_Capture_Final_Syllable_2: "Vogal inicial no espaço",
	LIN_VIS_Final_Consonant_Sound_1: "Som consonantal final R",
	LIN_VIS_Medial_Consonant_Exp_1: "Som de consoante medial M",
	LIN_VIS_Manipulation_Integration_Syllabic_1: "Manipulação silábica 2",
	LIN_VIS_Integration_Syllables_Space_1: "Integração de sílabas no espaço",
	LIN_VIS_Same_Final_Syllable_1: "GUS Mesma sílaba final",
	LIN_VIS_Vowel_Sequence_Cards_3: "Sequência de Vogais 2",
	LIN_VIS_Consonant_Sequence_Cards_1: "Som consonantal inicial 1",
	LIN_VIS_Consonant_Sequence_Cards_2: "Consoante medial",
	LIN_VIS_Phonemic_Synthesis_1: "Síntese fonêmica 1",
	LIN_VIS_Phonemic_Synthesis_Cards_1: "Síntese fonêmica 2",
	LIN_VIS_Phonemic_Analysis_1: "Análise fonêmica",
	LIN_VIS_Phonemic_Integration_1: "Integração fonêmica 1",
	LIN_VIS_Phonemic_Integration_2: "Integração fonêmica 2",
	LIN_VIS_Build_Words_Syllables_1: "Integração para formar palavras",
	VIS_KIN_1_Master_Pieces: "Obras-primas para colorir",
	NAT_VIS_1_Ecosystem: "Ecossistemas da Terra",
	INTRA_VIS_1_Creation_Emotions: "Criação de Emoções",
	INTER_LIN_1_Inclusion_Values: "Valores de inclusão",
	LIN_MAT_Count_Syllables_Filling_1: "Identificando o número de sílabas",
	LIN_KIN_Vocal_Initial_1: "Vogal inicial",
	LIN_VIS_Vowel_Sequence_Cards_2: "Sequência vocálica",
	LIN_VIS_Memorice_Initial_Syllable_1: "Memorize a sílaba inicial" ,
	MAT_VIS_1_Sum_Sets:"Soma dos conjuntos 0 a 5",
	MAT_VIS_2_Sum_Sets: "Soma dos conjuntos 6 a 10",
	MAT_VIS_3_Sum_Sets: "Soma dos conjuntos 11 a 20",
	MAT_VIS_1_Subtraction_Sets:"Subtração dos conjuntos de 0 a 5",
	MAT_VIS_2_Subtraction_Sets:"Subtração dos conjuntos 6 a 10",
	MAT_VIS_3_Subtraction_Sets:"Subtração dos conjuntos 11 a 20",
	MAT_VIS_Comparing_Weights_1:"Comparação de pesos",	
	KIN_VIS_Pattern_Trace_1:"Traços fundamentais mistos",
	INTER_KIN_Lead_Flock_1:"Lidere o rebanho",
	INTRA_VIS_Emotion_Machine_1:"Classificador de emoção",
	VIS_KIN_Identify_Patterns_1:"Reconhecimento de padrões",
	NAT_KIN_Recycle_Ocean_1:"Resgate do oceano",
	MUS_VIS_Musical_Instruments_1:"Instrumentos musicais",
	NAT_VIS_Sources_Energy_1:"Fontes de energia",




	KIN_VIS_Modeling_Clay_1:"Crie objetos de plasticina",
	VIS_KIN_Identify_Volumes_1:"Identifique os volumes",
	VIS_MAT_Color_Mixer_1:"Misture cores primárias",
	VIS_MAT_Color_Mixer_2:"Misture cores secundárias",
	VIS_NAT_3D_Coloring_1:"Cor em 3D",
	VIS_KIN_Master_Pieces_Artel_1:"Cor em 2D",
	VIS_KIN_Chromatic_Circle_1:"Ordene as cores", 
	VIS_NAT_Stickers_Histories_1:"Crie sua história",
	VIS_MAT_Step_Drawing_1:"Desenhe passo a passo", 


	KIN_LIN_Cursive_Letters_Pair_Vocals:"Traçado de vogais cursivas maiúsculas e minúsculas",
	LIN_VIS_Recognize_Words_1:"Palavras que começam com vogais",
	KIN_LIN_Cursive_Letters_Pair_Consonants:"Traçar letras cursivas maiúsculas e minúsculas 1",
	LIN_VIS_Select_Image_Article_1:"Relação do artigo com a palavra de acordo com o gênero",
	LIN_VIS_Identify_Syllable_Word_1:"Identificar sílaba inicial de palavras que começam com M",
	LIN_VIS_Recognize_Words_3:"Combine o som da sílaba com a imagem",
	LIN_VIS_Select_Image_Article_2:"Combine artigos definidos com uma palavra de acordo com o gênero",
	LIN_KIN_Discover_Words_Start_Letter_2:"Ler palavras que começam com a letra D",
	LIN_VIS_Recognize_Words_2:"Ler palavras contendo ca, co, cu",
	LIN_KIN_Discover_Words_Start_Letter_1:"Identifique as palavras que começam com a letra T",
	LIN_VIS_Select_Words_Rr_1:"Ler palavras com r e rr reconhecendo seu fonema",
	LIN_VIS_Form_Words_Contain_1:"Forma palavras que levam H de sílabas",
	LIN_VIS_Order_Words_Sentence_1:"Ordenar palavras para formar frases",
	LIN_VIS_Select_Words_J_1:"Ler palavras contendo J",
	LIN_VIS_Recognize_Words_4:"Identificar o som inicial silábico ga, go, gu",
	LIN_VIS_Recognize_Words_5:"Identifique o som silábico final ga, go, gu",
	LIN_VIS_Recognize_Words_6:"Reconhecer sons silábicos ga, go, gu, gue, gui, ge, gi",
	LIN_VIS_Form_Words_Contain_2:"Forme palavras que tenham a letra b",
	LIN_VIS_Identify_Article_1:"Combine o artigo com a palavra de acordo com o gênero",
	LIN_VIS_Read_Words_F_1:"Leia as palavras que têm F e classifique-as de acordo com o número de sílabas",
	LIN_VIS_Recognize_Strong_Soft_R_1:"Reconhecer palavras que têm r forte e r suave",
	Kin_Lin_Cursive_Letters_Pair_Consonants_2:"Traçar letras maiúsculas e minúsculas 2",
	Lin_Mat_Count_Syllables_Filling_3:"Identifique o número de sílabas",
	Lin_Vis_Underline_Word_1:"Identifique palavras que levam ll",
	Lin_Kin_Read_Relation_1:"Ler e combinar palavras singular plural",
	Lin_Vis_Read_Words_1:"Ler palavras com Ñ e sua relação com a imagem",
	Lin_Kin_Classify_Words_Syllables_1:"Classifique as palavras com V de acordo com o número de sílabas",
	Lin_Kin_Read_Relation_Words_1:"Combine a palavra com o desenho que a representa",
	Lin_Vis_2_Space_Words_K:"Encontrar palavras que começam com K",
	Lin_Vis_Form_Words_Contain_4:"Forma palavras que têm X de sílabas",
	Lin_Kin_Classify_Words_Syllables_2:"Classifique as palavras com Y de acordo com o número de sílabas",
	Lin_Kin_Discover_Words_Start_Letter_3:"Ler palavras encontro consonantal pr",
	Lin_Vis_Form_Words_Contain_3:"Forma palavras que recebem pl com sílabas",
	Lin_Kin_Words_Relation_Br_1:"palavras consonantais br",
	Lin_Vis_Completed_Words_Bl_1:"Palavras completas do encontro consonantal bl",
	Lin_Vis_Order_Words_Sentence_2:"Ordenar palavras para formar frases",
	Lin_Kin_Discover_Words_Start_Letter_4:"Ler palavras do encontro consonantal fr",
	Lin_Vis_Consonantal_Groups_Read_Gr:"Ler palavras grupo consonantal gr",
	Lin_Kin_Words_Rhyme_Relation_1:"reconhecer palavras que rimam",
	Lin_Vis_Consonantal_Groups_Read_Gr:"Ler palavras grupo consonantal gr",
	Lin_Vis_Underline_Word_1:"Identifique palavras que levam ll",
	Lin_Vis_Read_Description_Word_1:"Leia e combine a descrição correta da palavra",
	LIN_KIN_Discover_Words_Start_Letter_5:"Ler palavras escritas com encontros consonantais pl pr",
	LIN_VIS_Select_Element_Group_1:"Distinguindo palavras de encontros consonantais bl br",
	LIN_VIS_Select_Element_Group_2:"Distinguir palavras que são escritas com cl cr",
	LIN_KIN_Listening_Relation_Words_1:"Distinguir as palavras encontros consonantais gl gr",
	LIN_VIS_2_Space_Words_Dr:"Reconhecer palavras compostas grupo consonantal dr",
	LIN_KIN_Discover_Words_Start_Letter_6:"Ler palavras que são escritas com ce ci",
	LIN_VIS_Form_Words_Contain:"Forma palavras com um certo número de sílabas",
	LIN_VIS_Complete_Word_1:"Distinguir palavras com güe, güi, gue, gui",
	LIN_VIS_Complete_Word_2:"Distinguir palavras com a sílaba ge, gi",
	LIN_VIS_Found_Words_Qui_1:"Distinguir palavras com o que ou quem",
	LIN_VIS_Consonantal_Groups_Find_Fr_Fl:"Distinção de palavras encontro consonantal fr fl",

	LIN_KIN_Read_Relation_Words_2:      "Combine a palavra com o desenho que a representa",
	LIN_VIS_Body_Parts_1:               "Partes do corpo",
	LIN_MAT_Count_Syllables_Filling_4 : "Identifique o número de sílabas",
	LIN_KIN_Machine_Words_1                      : "Distinguir as palavras encontros consonantais tl e tr",
	LIN_VIS_Identify_Article_2                   : "Frases completas com artigo definido coerente",
	LIN_VIS_Identify_Article_3                   : "Selecione o item apropriado",
	LIN_VIS_Recognize_Words_Number_Syllables_1   : "Reconhecer palavras de acordo com o número de sílabas",
	LIN_VIS_Space_Letters_Hand_Written_1         : "Reconhecer letras manuscritas do alfabeto",
	LIN_VIS_Recognize_Common_Proper_Nouns_1      : "Reconhecer substantivos comuns e próprios",
	LIN_VIS_identify_Personal_Pronouns_1         : "Leia textos curtos que usam pronomes pessoais",
	LIN_VIS_Identify_Words_Start_Capital_Letter_1: "Reconhecer palavras maiúsculas",
	LIN_VIS_Words_Rhyme_1:"Reconhecer palavras que rimam",
	LIN_VIS_Recognize_Verbs_1:"Associar imagem com ações.",
	LIN_VIS_Identify_Words_Homophones_1:"Distinguir homófonos",
	LIN_VIS_Understand_Story_1:"Entender uma história",
	LIN_VIS_Complete_Word_3:"Reconhecer adjetivos qualificativos",
	LIN_VIS_Use_Gender_Words_1:"Use palavras de gênero corretamente",
	LIN_VIS_Identifies_Synonyms_1:"Reconhecer sinônimos",
	LIN_VIS_Words_Singular_Plural_1:"Distinguir quando palavras singulares e plurais são usadas",
	LIN_VIS_Complete_Word_CR_1:"Frases completas com a palavra que falta",


	LIN_KIN_Discover_Words_Start_Letter_7: "Identifique as palavras que começam com a letra T",
	LIN_KIN_Kitchen_Words_Diphthongs_1:    "Ler palavras com ditongo",
	LIN_VIS_Read_Words_Fl_1              : "Ler palavras encontro consonantal fl",   
	LIN_VIS_Identify_Antonyms_1          : "Combine duas palavras com significados opostos",       
	LIN_VIS_Interrog_Phrases_1           : "Sublinhe frases interrogativas e exclamativas",       
	LIN_VIS_Recognize_Strong_Soft_R_2    : "Distinguir palavras com r-rr",             
	LIN_VIS_Identify_Verb_Tenses_1       : "Utilizar corretamente presente, passado e futuro",           
	LIN_VIS_Identify_Words_Homonyms_1    : "Identificar significado de uma palavra homônima",             
	LIN_VIS_Identify_Diminutive_Words_1  : "Identificar diminutivo de palavras dadas",               
	LIN_VIS_Identify_Augmentative_Words_1: "Identificar aumentativos",  


	LIN_VIS_Read_Words_CL_1:"Palavras completas encontro consonantal cl.",
	LIN_VIS_Read_Words_GL_1:"Ler palavras que têm o encontro consonantal gl",
	LIN_VIS_Read_Words_DR_1:"Ler e entender frases com palavras conjunto consonantal dr",
	LIN_VIS_Comprises_Legends_1:"entender lendas",
	LIN_VIS_Understands_Fable_1:"entender fábulas",
	LIN_VIS_Read_Comprehensive_News:"Leitura completa de notícias",
	LIN_VIS_Read_Comprehensive_Recipes:"Ler completamente uma receita",


	report_for_student:	"Relatório para",
	report_for_course:	"Relatório para o curso",
	intelligence:	"Inteligência",
	license_count_exceded:	"Você atingiu o número máximo de licenças em uso, entre em contato com seu provedor.",
	intelligences:	"Inteligências",
	book_less_used:	"Notebook menos usado",
	book_most_used:	"Notebook mais usado",
	total_use:	"Total de horas exercidas",
	activities_completed:	"Atividades concluídas",
	caligrafix_current_general_progress:	"Progresso através de notebooks interativos",
	caligrafix_progress_analysis:	"Análise de uso",
	caligrafix:	"Caligrafix",
	all_notebooks:	"Em todos os cadernos",
	tooltips_current_caligrafix_courses_progress:	"Comparação do andamento dos cursos para cada bloco de anotações interativo.",
	tooltips_current_caligrafix_progress:	"Progresso global atual acumulado por cada notebook interativo.",
	intelligences_progress:	"Progresso das inteligências",
	total_time:	"Tempo total de aprendizado com o aplicativo PleIQ",
	comments_for:	"Registro anedótico e pedagógico de",
	student_comments:	"Registro anedótico e pedagógico",
	student_comments_short:	"Registro",
	add_comment:	"Editar",
	empty_comment:	"Escreva aqui o seu comentário",
	unassigned_courses:	"Cursos não atribuídos", 
	ab_planification_title  : "Planos de aula",
	ab_orientation_title    : "Orientações",
	ab_orientation_tooltip  : "Planos de aula sugeridos para famílias",
	ab_planification_tooltip: "Planos de aula sugeridos para professores",
	ab_search               : "Digite sua pesquisa",
	ab_no_results           : "Não há resultados",
	ab_global_score         : "Avaliação Global",
	ab_stars_tooltip        : "Qualificação",
	ab_download_tooltip     : "Baixe em .PDF",
	ab_filters: "Filtro",
	ab_clear_filter: "Filtro limpo",
	ab_loading: "Carregando...",

	server_problem: 'Oh! Houve um problema.',
   	server_problem_retry: 'Tente novamente.',


	unassigned_students:"Perfis não atribuídos" ,
   
	title_registration_deletes: "Exclusão de conta",
	delete_account_form_title: "Exclusão de conta",
	delete_account_form_subtitle: "Você pode excluir sua conta se desejar. Se você mudar de ideia posteriormente, não poderá recuperá-lo e os produtos ativados anteriormente não poderão ser reutilizados, seus dados serão excluídos de nossa plataforma.",
	reason: "Razão",
	account_delete_confirmation: "Li e entendi as implicações de excluir minha conta.",
	delete_account: "Eliminar conta", 
	delete_account_deleted: "Sua conta foi excluída com sucesso.",

	user_delete_not_parent: "As contas escolares não podem ser apagadas desta forma, devem ser solicitadas pela instituição.",

	role_teacher: "Educador",
	role_coordinator: "Administrador",
	role_representative: "Representante",
	role_observer: "Observador",
	new_user_teacher: "Criar novo educador",
	new_user_coordinator: "Criar novo administrador",
	new_user_representative: "Create new Representative",
	new_user_observer: "Criar novo Observador",
	user_children: "Estudantes",
	we_will_send_email_new_user: "Enviaremos um e-mail de convite para o novo usuário.",
	teacher_data: "Dados do educador",
	coordinator_data: "Dados do administrador",
	representative_data: "Representative data",
	observer_data: "Dados do observador",
	create: "Criar",
	email_placeholder_retype_user:"Confirmação de e-mail",
	edit_teacher: "Editar educador",
	edit_coordinator: "Edit Administrator",
	edit_representative: "Editar representante",
	edit_observer: "Editar observador",
  
	add_courses: "Adicionar curso",
	add_children: "Adicionar aluno",
  
	student_representants: "Representantes",
	user_name: "Nome",
	add_representative: "Adicionar representante",
	tooltips_profiles: "Gerencie os diversos perfis na plataforma.",
	remove_representative_confirmation_title: "Tem certeza?",
	remove_representative_confirmation_message: "Se você confirmar esta ação, o representante será removido deste perfil.",



	my_account: "Minha conta",
	my_account_description: "Verifique as configurações da sua conta na plataforma.",
	my_books: "Cadernos interativos",
	my_books_description: "Verifique o andamento da escola nos recursos do Caligrafix.",
	intelligences_description: "Confira o progresso geral da escola em inteligências múltiplas.",
	my_courses: "Meus cursos",
	my_courses_description: "Revise os grupos de alunos atribuídos a você.",
	progress_reports: "Relatórios de progresso",
	progress_reports_description: "Prepare seus relatórios de progresso personalizados.",
	curricular_coverage: "Cobertura curricular",
	curricular_coverage_description: "Revise os objetivos de aprendizagem cobertos dos alunos.",
	activity_assignment: "Atribuição de atividades",
	activity_assignment_description: "Atribua aos seus alunos atividades educacionais para fazer.",
	planifications_and_orientations: "Planejamento e orientação",
	planifications_and_orientations_description: "Conteúdo pedagógico e localização dos objetivos pedagógicos associados ao conteúdo.",
	resources: "Significa",
	resources_description:"Descubra os recursos educacionais que você pode baixar.",
	video_tutorials: "Tutoriais em vídeo",
	video_tutorials_description: "Nossa coleção de vídeos sobre tudo relacionado ao ecossistema PleIQ.",
	pleiq_learning: "Academia",
	pleiq_learning_description: "Acesse os cursos preparatórios no PleIQ School e seja certificado em gestão da plataforma.",
	educators_4_0: "Educadores 4.0",
	educators_4_0_description: "Compartilhe experiências com outros educadores em nossa comunidade do Facebook",
	profiles_description: "Gerencie os perfis dos usuários que utilizam a plataforma.",
  
	download: "Baixar",
	home: "Começar",
	
	school_home_hello: "Oi $user!",
	school_home_subtitle: "Que faremos hoje?", 
	school: "Escola",
	learning: "Aprendendo",
	administration: "Gestão",
	comming_soon: "Em breve",
	premium_benefits: "Conheça as vantagens de ser Premium",
	premium: "Premium",
  
	resource_cube_lin: "Cubo Lingüístico",
	resource_cube_lin_desc: "PDF do primeiro cubo do conjunto de 8 cubos para imprimir.",
	resource_cube_lin_url: "https://pleiq.com/trial/pleiq_cube_templates_spanish.pdf",
	resource_pleiq_cube: "PleIQ Digital",
	resource_pleiq_cube_desc: "PDF do conjunto de 8 cubos para imprimir.",
	resource_pleiq_cube_url: "https://pleiq.com/digital/pleiq_digital_templates_spanish.pdf",
	resource_didactic_guide: "Guia Didática",
	resource_guide_latam_desc: "PDF da guia didática versão LATAM.",
	resource_guide_latam_url: "https://pleiqpro.s3.amazonaws.com/Assets/PDF/didactic_guide_latam.pdf",
	resource_guide_chile_desc: "PDF da guia didática versão Chile.",
	resource_guide_chile_url: "https://pleiqpro.s3.amazonaws.com/Assets/PDF/didactic_guide_chile.pdf",
	resource_cx_books: "Cuadernillos interativos Caligrafix + PleIQ",
	resource_cx_cal_desc: "Caderno da Linha de Caligrafia Interativa DF (6 a 8 anos).",
	resource_cx_cal_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book_cal.pdf",
	resource_cx_jcs_desc: "PDF Cartilha Interativa Linha Fonoaudiologia (4 a 6 anos).",
	resource_cx_jcs_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book_jcs.pdf",
	resource_cx_ibook_desc: "Livreto Interativo PDF Linha Pré-escolar (3 a 5 anos).",
	resource_cx_ibook_url: "https://pleiq.com/downloads/caligrafix_pleiq_interactive_book.pdf",
	
	tooltips_school_resources: "Descubra os recursos educacionais que você pode baixar.",
  
  


	KIN_LIN_Script_Letters_Pair_Vocals: 	  "Rastreamento de vogal em letras maiúsculas e minúsculas",
	KIN_LIN_Script_Letters_Pair_Consonants:	  "Curso de letras de script maiúsculas e minúsculas 1",
	KIN_LIN_Script_Letters_Pair_Consonants_2: "Curso de letras maiúsculas e minúsculas do script 2",


	video_tutorials_title              : "Tutoriais em vídeo",
	video_tutorials_duration           : "Duração",
	video_tutorials_general_title      : "Em geral",
	video_tutorials_books_title        : "Cadernos interativos",
	video_tutorials_cubes_title        : "Cubos interativos",
	video_tutorials_pleiq_school_title : "Plataforma Escolar PleIQ",
	video_tutorials_pleiq_app_title    : "Aplicativo PleIQ",
	level_books                        : "Cadernos Interativos",
	pick_a_book                        : "Pesquisar caderno por nome",
	edit_level_books                   : "Editar cadernos interativos",
	add_book                           : "Adicionar caderno interativo",
	progress_reports_for               : "%{name} relatórios de progresso",
	select_progress_report_type        : "Escolha o tipo de relatório de progresso que deseja ver",
	select_progress_report_student     : "Selecione o aluno para consultar os relatórios de progresso",
	select_progress_report_course      : "Selecione o curso para consultar os relatórios de progresso",
	created_by                         : "Criado pela",
	view                               : "Ver",
	date                               : "Data",
	create_new_progress_report         : "Criar novo relatório",
	by_course                          : "Por curso",
	by_student                         : "Por aluno",
	qualitative_report                 : "Relatório qualitativo",
	domain                             : "Domínio",
	teacher                            : "Professor",
	progress_report_for                : "Relatório de progresso %{name}",
	progress_report_explanation        : "Os domínios de realização que são adicionados automaticamente ao relatório são definidos pelos marcadores e atividades do PleIQ App localizados nos recursos educacionais interativos que os alunos trabalharam, onde:",
	progress_report_legend             : "Não observado = N Iniciado = I Em andamento = P Adquirido = A",
	progress_reports_premium_notice    : "No modo Premium, você pode gerar relatórios ilimitados e mantê-los em um histórico para consultá-los sempre que quiser, além de compará-los para ver a evolução do seu progresso.",
	progress_report_reminder           : "Lembre-se de que você pode selecionar a caixa de domínio para modificar ou adicionar seu valor.",
	compare_progress_reports           : "Comparar relatórios",
	progress_report_saved              : "Relatório de progresso",
	progress_report_saved_message      : "O relatório foi salvo com sucesso",
	_ok                                : "Entendido",
	report                             : "Relatório",
	progress_report_type_select        : "Selecione o tipo de relatório a ser criado",
	school_data                        : "Namoro escolar",
	school_logo                        : "Logotipo",
	school_logo_msg                    : "Somente imagens no formato PNG são suportadas.",
	upload_image                       : "Enviar Imagem",
	school_name                        : "Inhame",
	image_is_not_png                   : "O formato deve ser PNG",
	image_is_too_big                   : "A imagem é muito grande",

	delete_user_confirmation_title     :"Excluir confirmação",
	delete_user_confirmation_message   :"Tem certeza que deseja excluir este perfil?",
	next                               :"Próximo",
	tooltips_school_progress_reports   :"Gere relatórios de progresso com base nos objetivos de aprendizagem.",

	school_pro_promo_title:                       "Junte-se a escolas premium",
	school_pro_promo_subtitle:                    "Torne-se premium e aproveite ao máximo todos os recursos da plataforma",
	school_pro_promo_free:                        "versão gratuita",
	school_pro_promo_premium:                     "versão premium",
	school_pro_promo_interactive_resources:       "Utilizando os recursos interativos com o aplicativo PleIQ",
	school_pro_promo_progress_analysis:           "Monitoramento e análise do progresso",
	school_pro_promo_general_progress:            "Relatório de progresso global",
	school_pro_promo_planifications:              "Planejamento e orientações",
	school_pro_promo_progress_reports:            "Seção de relatórios de progresso",
	school_pro_promo_progress_reports_download:   "Salvamento, download e edição ilimitados de relatórios de progresso",
	school_pro_promo_progress_reports_cmp:        "Comparação de relatório de progresso",
	school_pro_promo_task_assignment:             "Atribuição de atividade (em breve)",
	school_pro_promo_curricular_coverage:         "Relatórios de cobertura curricular (em breve)",
	school_pro_promo_unlimited_profiles:          "Perfis de usuário ilimitados",
	school_pro_promo_unlimited_registry:          "Históricos de dados ilimitados",
	school_pro_promo_disclamer:                   "*Recursos disponíveis apenas durante o período de teste de 90 dias ou usando os valores disponíveis para experimentar na versão gratuita.",
	school_pro_promo_footer:                      "Escreva-nos para <b>plataforma@caligrafix.com</b> e solicite mais informações para obter a versão Premium.",
	"school_pro_promo_planification_generator": "Meu assistente educacional de IA",


	school_home_cant_use_pro:      "O período de teste desta funcionalidade premium terminou. Contate seu consultor para adquirir o modo premium ou entre em contato conosco pelo e-mail hola@pleiq.com",
	school_home_cant_use_disabled: "Esta funcionalidade está planejada para ser habilitada em breve.",


	LIN_VIS_Associate_Cognates_1    : "Lembrar e associar cognatos de meio de transporte",
	LIN_KIN_Relation_3D_Figures_1   : "Lembrar e associar cognatos de figuras tridimensionais",
	LIN_KIN_Read_Understand_Select_1: "Leia e compreenda",
	LIN_VIS_Apply_Cognates_1        : "Aplicar cognatos no estudo",
	LIN_VIS_Face_Parts_1            : "Distinguir e lembrar o vocabulário das partes do rosto",
	LIN_VIS_Recognize_Words_Rhyme_1 : "Reconhecer palavras que rimam",
	LIN_VIS_Fluently_Read_Words_1   : "Ler fluentemente palavras de alta frequência",
	LIN_VIS_Vocabulary_Professions_1: "Distinguir e lembrar o vocabulário de profissões e ofícios",
	LIN_VIS_2D_Cognates_1           : "Lembrar e associar cognatos de figuras bidimensionais",
	LIN_VIS_Recognize_Synonym_1     : "Desenvolva consciência semântica",
	LIN_VIS_Associate_Articles_1    : "Associar e escrever determinados artigos",
	LIN_VIS_Order_Words_1           : "Ler, compreender e escrever frases",
	LIN_VIS_Riddle_1                : "Compreensão de textos: enigmas",
	LIN_VIS_Read_TongueTwisters_1   : "Leia e pratique trava-línguas",
	LIN_VIS_Stories_FSC_2               : "Preguntas Lectura vocalizada #2",
	LIN_VIS_Stories_FSC_3               : "Preguntas Lectura vocalizada #3",
	KIN_VIS_Pattern_Trace_1             : "Trazos fundamentales mixtos",
	INTRA_VIS_Emotion_Machine_1         : "Clasificador de emociones",
	LIN_VIS_Dobble_1                    : "Dobble",
	LIN_VIS_Coloring_Syllables_4        : "Colorear por cantidad de sílabas",
	LIN_VIS_Position_Objects_1          : "Arriba y Abajo",
	INTER_VIS_Values_Positive_Negative_1: "Valores positivos y negativos", 
	LIN_VIS_Information_Integration_Cards_FSC_MX : "Integración de la información",
	LIN_KIN_Discover_Words_Start_Letter_8        : "Sopa de Letras",
	LIN_VIS_Stories_FSC_1                        : "Preguntas Lectura vocalizada #1",
	VIS_KIN_1_Free_Painting                      : "Pintura Libre",
	LIN_VIS_2_Space_Words_FSC_1                  : "Letras en el espacio",
	VIS_NAT_3D_Coloring_Zeppelin                 : "Colorear en 3D",


	LIN_VIS_Identifies_Beneficial_Harmful_Elements  : "Identifique palavras que representem elementos benéficos e prejudiciais ao meio ambiente.",
	LIN_KIN_Tracing_Upper_Practice_1                : "Rastreando letras maiúsculas vinculadas de A a F.",
	LIN_KIN_Tracing_Lower_Practice_1                : "Rastreando letras minúsculas vinculadas de A a F.",
	LIN_KIN_Tracing_Upper_Practice_2                : "Rastreando letras maiúsculas vinculadas de G a L.",
	LIN_KIN_Tracing_Lower_Practice_2                : "Rastreando letras minúsculas vinculadas de G a L.",
	LIN_KIN_Tracing_Upper_Practice_3                : "Rastreando letras maiúsculas vinculadas de M a P.",
	LIN_KIN_Tracing_Lower_Practice_3                : "Rastreando letras minúsculas vinculadas de M a P.",
	LIN_KIN_Tracing_Upper_Practice_4                : "Rastreando letras maiúsculas vinculadas de Q a V.",
	LIN_KIN_Tracing_Lower_Practice_4                : "Rastreando letras minúsculas vinculadas de Q a V.",
	LIN_KIN_Tracing_Upper_Practice_5                : "Rastreamento de letras maiúsculas vinculadas de W a Z e dígrafos (ch, ll, rr).",
	LIN_KIN_Tracing_Lower_Practice_5                : "Rastreamento de letras minúsculas vinculadas de W a Z e dígrafos (ch, ll, rr).",
	LIN_VIS_Association_Initial_Letter_Word_3       : "Bacharelado",
	LIN_VIS_Read_Texts_Fluently                     : "Leia textos com fluência.",
	LIN_VIS_Sort_Alpha                              : "Classifique em ordem alfabética.",
	LIN_VIS_Article_Definite_Indefinite             : "Frases completas com artigos definidos e indefinidos.",
	LIN_VIS_Write_Nouns_Commons_Proper              : "Substantivos próprios e comuns.",
	LIN_VIS_Nouns_Plural_Singular                   : "Conte substantivos no plural e no singular.",
	LIN_VIS_Noun_Gender                             : "Use o substantivo de acordo com o gênero.",
	LIN_VIS_Relate_Nouns_Individual_Colectives      : "Relacionar substantivos individuais e coletivos.",
	LIN_VIS_Use_Qualifying_Adjectives_Correctly     : "Selecione adjetivos qualificativos de acordo com o contexto.",
	LIN_VIS_Select_Demonstrative_Adjectives         : "Aplique adjetivos demonstrativos de acordo com o contexto.",
	LIN_VIS_Replace_Subject_Personal_Pronoun        : "Substitua sujeito por pronome pessoal.",
	LIN_VIS_Recognizes_Words_Same_Syllable          : "Reconhecer palavras que contenham a mesma sílaba.",
	LIN_VIS_Consonantal_Groups_Find_Ge_Gi           : "Distinguir palavras com grupos consonantais Ge-Gi.",
	LIN_VIS_Apply_Plural_Words_3                    : "Use palavras no plural.",

	LIN_KIN_Tracing_Upper_Practice_Script_1   : "Rastreando letras maiúsculas de A a F.",
	LIN_KIN_Tracing_Lower_Practice_Script_1   : "Rastreando letras minúsculas de A a F.",
	LIN_KIN_Tracing_Upper_Practice_Script_2   : "Rastreando letras maiúsculas de G a L.",
	LIN_KIN_Tracing_Lower_Practice_Script_2   : "Rastreando letras minúsculas de G a L.",
	LIN_KIN_Tracing_Upper_Practice_Script_3   : "Rastreando letras maiúsculas de M a P.",
	LIN_KIN_Tracing_Lower_Practice_Script_3   : "Rastreando letras minúsculas de M a P.",
	LIN_KIN_Tracing_Upper_Practice_Script_4   : "Rastreando letras maiúsculas de Q a V.",
	LIN_KIN_Tracing_Lower_Practice_Script_4   : "Rastreando letras minúsculas de Q a V.",
	LIN_KIN_Tracing_Upper_Practice_Script_5   : "Rastreamento de letras maiúsculas de W a Z e dígrafos (ch, ll, rr).",
	LIN_KIN_Tracing_Lower_Practice_Script_5   : "Rastreamento de letras minúsculas de W a Z e dígrafos (ch, ll, rr).",
	LIN_VIS_Qualifying_Adjective_1            : "Associação da letra inicial com a palavra",
	LIN_VIS_Recognize_Diphthong_Words         : "Identifique palavras que contenham ditongo",
	LIN_VIS_Identify_Hiatus_Words             : "Identifique o hiato na palavra.",
	LIN_VIS_Identify_Tonic_Atonic_Syllable    : "Identifique a sílaba tônica e átona nas palavras.",
	LIN_VIS_Identify_Serious_Words            : "Identifique e escreva palavras sérias.",
	LIN_VIS_Identify_Esdrujulas_Words         : "Identificar e escrever palavras esdrújulas.",
	LIN_VIS_Identify_Sobresdrújulas_Words     : "Identificar e escrever palavras over-esdrújula",
	LIN_VIS_Apply_Rules_NV_DV_BV_Venir        : "Aplicar corretamente as regras NV - DV - BV - Come.",
	LIN_VIS_Correct_Use_AHA                   : "Aplicar corretamente o uso de aí, aí, ai",
	LIN_VIS_Write_Nouns_4                     : "Escreva substantivos aplicando as regras de ortografia.",
	LIN_VIS_Association_Initial_Letter_Word_4 : "Use os adjetivos apropriados de acordo com o contexto.",
	LIN_VIS_Identify_Verb_Time                : "Identifique os tempos verbais em um texto.",
	LIN_VIS_Identify_Conjunctions             : "Identifique as conjunções",
	LIN_VIS_Identify_Subject_Predicate        : "Identifique o sujeito e o predicado em uma frase.",

	LIN_VIS_Consonantal_Groups_Find_Gue_Gui : "Reconheça palavras que contenham os sons GUE e GUI.",
	LIN_VIS_Conjugation_Verb                : "Conjugação de verbos.",
	LIN_VIS_Consonant_Cluster_3             : "Reconhece palavras escritas com o grupo consonantal mp e mb.",
	LIN_VIS_Identifies_Diminutives_3        : "Identifique os diminutivos.",
	LIN_VIS_Write_Correct_Use_B             : "Aplique corretamente a letra b.",
	LIN_VIS_Write_Correct_Use_H             : "Aplique corretamente a letra h.",
  

	digital_paper: "Biblioteca Papel Digital",
	digital_paper_description: "Consulte os cadernos Caligrafix em sua versão digital.",
	
	LIN_VIS_Relationship_Verbs         : "Relação de verbos com imagem.",
	LIN_VIS_Demonstrative_Pronouns     : "Pronomes demonstrativos.",
	LIN_VIS_Order_Syllables_Accordingly: "Ordene as sílabas e crie palavras usando as sílabas ge-gi-je-ji.",
	LIN_VIS_Figurative_Language_3      : "Interprete estrofes em linguagem figurada.",
	LIN_VIS_Apply_Articles_4           : "Aplique os artigos corretamente em uma frase.",
	LIN_VIS_Personal_Pronouns_4        : "Substitua corretamente os substantivos por pronomes.",
	LIN_VIS_Recognize_Nouns            : "Reconhecer substantivos de pronomes demonstrativos.",
  
	LIN_VIS_Classify_Words_Accordingly_3: "Classifique as palavras de acordo com as sílabas CE - CI.",

	LIN_KIN_Accent_Sharp_Words               : "Selecione e escreva palavras nítidas.",
	LIN_VIS_Identify_Demonstrative_Adjectives: "Identificar adjetivos demonstrativos em um texto.",
	LIN_VIS_Adverds_Text_Oral_Written        : "Compreender a função dos advérbios em textos orais e escritos.",
	LIN_VIS_Use_Connectors_Sentence          : "Use os conectores de acordo com a frase.",
	LIN_VIS_Recognize_Prefixes_Suffixes      : "Reconhecer prefixos e sufixos.",
	LIN_KIN_Distinguish_Word_Family          : "Distinguir família de palavras.",

	LIN_VIS_Recognize_Strong_Soft_3               : "Identifique palavras que tenham güe ou güi.",
	LIN_VIS_Words_Consonant_Groups                : "Distinguir palavras com grupos consonantais nf e nv.",
	LIN_KIN_Identify_Word_Families_3              : "Identifique famílias de palavras.",
	LIN_VIS_Apply_Prefix_3                        : "Aplique o prefixo em uma frase.",
	LIN_VIS_Suitable_Connectors_3                 : "Use os conectores apropriados.",
	LIN_VIS_Reading_Comprehension_Story_3         : "Compreensão de leitura de uma lenda.",
	LIN_VIS_Write_Letter_3                        : "Escreve uma carta.",
	LIN_VIS_Identify_Prepositions                 : "Identifique as preposições.",
	LIN_VIS_Reading_Comprehension_Sayings_Proverbs: "Compreensão de leitura (linguagem figurada: ditos e provérbios).",


	LIN_VIS_Reading_Comprehension_Legend_3         : "Compreensão de leitura de uma história.",
	LIN_VIS_Reading_Comprehension_Non_literary     : "Inclui um texto não literário.",
	LIN_VIS_Apply_Suffix_3                         : "Aplique o sufixo apropriado ou não.",
	LIN_VIS_Understands_Texts_Non_literary_Comic   : "Inclui um texto baseado em quadrinhos.",
	LIN_KIN_Machine_Recognize_Parts_News           : "Reconhecer as partes de uma notícia.",
	LIN_VIS_Reading_Comprehension_News_3           : "Compreende textos noticiosos.",

	LIN_VIS_Understands_Texts_Poem_P1   : "Autoavaliação de leitura de poemas.",
	LIN_VIS_Understands_Texts_Interview : "Inclui um texto baseado em uma entrevista.",
	LIN_VIS_Understand_Words_Spelling   : "Compreender a origem das palavras e a sua importância na ortografia.",
	LIN_VIS_Understands_Texts_Poem_P2   : "Inclui um texto baseado no poema.",

	LIN_VIS_Reading_Comprehension_Poem_3  : "Interpretar a linguagem figurada em um poema.",
	VIS_NAT_3D_Coloring_Chiloe            : "Igreja de Chiloé.",
	LIN_VIS_Reading_Comprehension_Myth_P2 : "Compreensão de leitura (Mito).",
	LIN_VIS_Read_Comics_Comprehensively   : "Leia as imagens e textos de uma história em quadrinhos de forma abrangente.",
	MUS_VIS_Runner_Notes_Recipe           : "Identificar os textos instrutivos (Receita).",
	LIN_VIS_Informative_Marker_Biography  : "A biografia 2.",
	LIN_VIS_Understands_Texts_News_P2     : "A notícia.",
	LIN_VIS_Understand_Text_Story_P1      : "A autoavaliação inclui um texto baseado na história.",
	LIN_VIS_Understand_Text_Story_P2      : "Inclui um texto baseado na história.",
	LIN_VIS_Reading_Comprehension_Fable_P1: "Autoavaliação de compreensão leitora (Fábula).",
	LIN_VIS_Reading_Comprehension_Fable   : "Compreensão de leitura (Fábula).",
	LIN_VIS_Reading_Comprehension_Myth_P1 : "Autoavaliação da compreensão leitora (Mito).",
	LIN_VIS_The_Biography_P1              : "Biografia de autoavaliação.",
	LIN_VIS_The_Biography_P2              : "A biografia.",
	LIN_VIS_Understands_Texts_News_P1     : "Autoavaliação das notícias.",
	LIN_VIS_Identify_Semantic_Fields      : "Identifique os campos semânticos.",
	LIN_VIS_Understand_Text_Poster        : "Inclui um texto baseado em um pôster.",
	VIS_NAT_3D_Coloring_Beto_Rocket       : "Beto jetpack.",

	KIN_LIN_Letters_Script_Upper_TLN2S       : "Rastreamento de consoantes em letras maiúsculas.",
	KIN_LIN_Letters_Script_Lower_TLN2S       : "Rastreamento de consoantes em letras minúsculas.",
	LIN_VIS_Identifying_Syllabic_Segmentation: "Identificação da segmentação silábica.",
	LIN_VIS_Same_Final_Syllable_2            : "Mesma sílaba final.",
	LIN_VIS_Vocals_Script_Upper              : "Rastreando as vogais em letras maiúsculas.",
	LIN_VIS_Vocals_Script_Lower              : "Rastreando vogais em letras minúsculas.",
	LIN_VIS_Letters_Script_Upper             : "Rastreamento de consoantes em letras maiúsculas.",
	LIN_VIS_Letters_Script_Lower             : "Rastreamento de consoantes em letras minúsculas.",
	MAT_VIS_Identify_Location_Concepts       : "Identifique os conceitos de localização: Acima, abaixo, dentro, fora.",
	MAT_VIS_Perception_Object_Position       : "Percepção de um objeto de acordo com a posição.",
	MAT_KIN_Identify_Directions_Birds        : "Identifique a direcionalidade: esquerda-direita, de cima para baixo.",
	MAT_VIS_Orientation_Before_After_1       : "Orientações temporais utilizando noções e relações de sequências (antes-depois) 1.",
	MAT_VIS_Orientation_Before_After_2       : "Oriente-se temporalmente usando noções e relações sequenciais (antes-depois) 2.",
	MAT_VIS_Orientation_Before_After_3       : "Oriente-se temporalmente usando noções e relações sequenciais (antes-depois) 3.",
	MAT_VIS_Classify_By_Attributes_Size      : "Classifique por dois ou três atributos por vez (Tamanho).",
	MAT_VIS_Classify_By_Attributes_Weight    : "Classifique por dois ou três atributos por vez. Peso (leve-pesado).",
	MAT_VIS_Quantify_Compare_Quantities_1    : "Use números para contar, identificar, quantificar e comparar quantidades 1.",
	MAT_VIS_Quantify_Compare_Quantities_2    : "Use números para contar, identificar, quantificar e comparar quantidades 2.",
	MAT_VIS_Quantify_Compare_Form_1          : "Use números para contar, identificar, quantificar e comparar quantidades.",
	MAT_VIS_Serialization_Quantity_1         : "Classifique por serialização por quantidade e números.",
	MAT_VIS_Sides_Vertices_Figures           : "Propriedades básicas de figuras geométricas; número de lados e vértices.",
	MAT_VIS_Identify_Figures_3D              : "Identifique atributos de corpos geométricos 3D.",
	MAT_VIS_Create_Patterns_Elements_2       : "Crie padrões de dois ou três elementos 2.",


	LIN_VIS_Memorice_Initial_Syllable_2 : "Memorizar Sílaba Inicial 2.",
	LIN_VIS_Manipulation_Sylabic_3      : "Forme palavras com Manipulação Silábica 2.",
	MAT_VIS_Attributes_Figures_2D_3D_1  : "Atributos das figuras 2D e 3D 1.",
	MAT_VIS_Create_Patterns_Elements_1  : "Crie padrões de dois ou três elementos 1.", 
	MAT_VIS_Count_To_39                 : "Conte até 39.",
	MAT_VIS_Number_Sequence_49          : "Sequência numérica até 49.",


	MAT_VIS_Time_Orientation_Clock: "Oriente-se temporalmente com o uso do relógio.",
	MAT_VIS_Tens_Composition_Tower: "Composição de 10; digite os dez.",
	MAT_VIS_Monster_Numbers       : "Utilize os números de quantidades de 11 a 19.",
	MAT_KIN_Tracing_Numbers_9     : "Rastreando os números de 0 a 9.",
	MAT_VIS_Number_Sequence_Maze_1: "Sequência numérica até 29.",
	MAT_VIS_Number_Sequence_69    : "Utilize a sequência numérica até 69.",
	MAT_VIS_Count_Tens_10         : "Conto dezenas 10.",
	MAT_VIS_Count_Five_By_Five    : "Vamos contar por 5.",


	VIS_LIN_3D_Coloring_Rocket_Lnn3             : "Marcador de aumento de criatividade (corpos geométricos)",
	MAT_VIS_Quantity_Relationship_92            : "Número - relação de quantidade até 92.",
	MAT_VIS_Additive_Composition_Decomposition  : "Composição e decomposição aditiva.",
	MAT_VIS_Subtraction_Until_20                : "Subtração até 20.",
	MAT_VIS_Measuring_Objects_1                 : "Medição de objetos.",
	LIN_VIS_Join_Words_Rhyme_1                  : "Reconheça palavras que rimam.",
	LIN_VIS_Initial_Vocal_Couples_1             : "Pares de vogais iniciais.",

	LIN_VIS_Ocean_Stars_To_10          : "Resgatar estrelas do mar do oceano até às 10h",
	MAT_VIS_Identify_Number_Rings_1_9  : "Utilize os números de quantidades de 11 a 19.",
	MAT_VIS_Composition_Additive_1     : "Composição e decomposição aditiva.",
	MAT_VIS_Use_Numbers_1_To_29        : "Utilize os números de quantidades de 1 a 29.",
	MAT_VIS_Completing_Graphics        : "Conclusão dos gráficos.",

	LIN_VIS_Stories_FSC_T2_1           : "Perguntas de leitura vocalizada nº 1",
	LIN_VIS_Vocals_Tearing_1           : "Rasgo vocálico.",
	LIN_VIS_Understand_Text_Poster_T2  : "Vamos fazer um pôster.",
	LIN_VIS_Stories_FSC_T2_2           : "Perguntas de leitura vocalizada nº 1",
	LIN_VIS_Domino_Rymes               : "Rimas Dominó.",
	LIN_VIS_Numeric_Sequence_T2        : "Sequência numérica.",
	LIN_VIS_Memorice_Initial_Syllable_3: "Memorize vogais.",
	LIN_VIS_1_Monster_Numbers_15_20    : "Monstro de biscoito numérico.",
	LIN_VIS_Matching_Phrases_Images_1  : "Combinando frases com imagens.",
	MAT_VIS_Comparing_Weights_2        : "Comparação de peso.",

	upload_csv_file: "Carregar .CSV"
};
//module.exports = pt; 
export default pt;
